<template>
  <div class="review">
    <div
      class="box d-flex flex-column ml-3 justify-content-center align-items-center"
    >
      <div>
        <h3>{{ test_details.title }}</h3>
      </div>
      <div>
        <h5>Duration => {{ test_details.duration }}</h5>
      </div>
      <div>Name => {{ user_det.name }}</div>
      <div>Email => {{ user_det.email }}</div>
      <div>Current Score => {{ tot_user_score }}/{{ test_total }}</div>
      <div
        class="box2 d-flex flex-column justify-content-center align-items-center"
        v-if="!user_det.reviewed"
      >
        <ol>
          <li v-for="(item, index) in subjective" :key="index">
            <div class="row d-flex justify-content-center mt-5">
              <div class="col">
                <h5>{{ item.question }}</h5>
              </div>
              <div class="col-1">({{ item.marks }})</div>
            </div>

            <small>Ans </small>
            <h6>{{ item.response }}</h6>
            <div class="d-flex flex-row">
              <input
                :value="item.marks_awarded"
                type="number"
                @input="onInput($event, index)"
                :max="item.marks"
              />
              <button class="btn" @click="saveMarks(index)">Save</button>
            </div>
          </li>

          <!-- <button class="btn">B</buttton> -->
        </ol>
      </div>

      <button @click="deleteNotification" class="btn btn-dark mybtn">
        Delete Notification and Notify Student
      </button>
      <button class="btn btn-dark mybtn" @click="viewFullResponse">
        View Full Response
      </button>
      <div>
        <button class="btn btn-light mybtn" @click="redirect">Go Back</button>
      </div>
    </div>
  </div>
</template>
<script>
import db from "../../firebase";
import store from "@/store";

export default {
  name: "ReviewTest",

  props: ["test_id", "user_test_id", "page_id"],
  async mounted() {
    console.log(this.test_id);
    console.log(this.user_test_id);
    console.log(this.page_id);
    //User responses
    let doc = await db
      .collection("tests" + this.page_id)
      .doc(this.test_id)
      .collection("test_takers")
      .doc(this.user_test_id)
      .get();
    this.user_det = doc.data();

    let doc2 = await db
      .collection("tests" + this.page_id)
      .doc(this.test_id)
      .get();
    this.test_details = doc2.data();
    db.collection("tests" + this.page_id)
      .doc(this.test_id)
      .collection("questions")
      .orderBy("id")
      .get()
      .then(snap => {
        let questions = snap.docs.map(doc => doc.data());
        for (let i = 0; i < questions.length; i++) {
          if (questions[i].correct == null) {
            //Subjective question get user response
            this.subjective.push({
              index: i,
              question: questions[i].question,
              response: this.user_det["responses"][i]["response"],
              marks_awarded: 0,
              marks: Number(+questions[i].marks)
            });
          }
        }
        this.test_total = this.test_details["total"];
      });
    store.state.loading = false;
  },
  data() {
    return {
      subjective: [],
      test_details: {},
      user_det: {},
      test_total: 0,
      val: "",
      value: 2,
      res: [],
      num: 0,
      avg: 0,
      percentile: 0,
      labels: [],
      rank: 0,
      total: "",
      title: "",
      score: 0
    };
  },
  computed: {
    tot_user_score() {
      let sum = 0;
      if (this.user_det.responses != null) {
        for (let i = 0; i < this.user_det.responses.length; i++)
          sum += Number(this.user_det.responses[i].marks);
      }
      return sum;
    }
  },
  methods: {
    onInput(event, index) {
      const newValue = parseInt(event.target.value);
      const clampedValue = Math.min(newValue, event.target.max);
      this.subjective[index].marks_awarded = clampedValue;
      this.$forceUpdate();
    },
    saveMarks(index) {
      let i = this.subjective[index].index;
      this.user_det.responses[i].marks = +this.subjective[index].marks_awarded;
      this.user_det["score"] = this.tot_user_score;
      this.user_det["reviewed"] = true;
      db.collection("tests" + this.page_id)
        .doc(this.test_id)
        .collection("test_takers")
        .doc(this.user_test_id)
        .set(this.user_det);
    },
    async deleteNotification() {
      if ("remark" in this.user_det) {
        await db
          .collection("test_completed_notifications" + this.page_id)
          .doc(this.user_test_id + this.test_id)
          .delete()
          .then(res => {
            this.saveTestData();
          });
      } else {
        swal({
          title: "Are you sure?",
          text:
            "You haven't gave remarks yet,click view full response to give remarks,Once deleted you wont be able to give remark",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            swal("You deleted Notification!", {
              icon: "success"
            });

            db.collection("test_completed_notifications" + this.page_id)
              .doc(this.user_test_id + this.test_id)
              .delete()
              .then(res => {
                this.saveTestData();
              });
          } else {
            swal("Notification didn't get deleted");
          }
        });
      }
      // this.user_det;

      // .then(() => {
      //Delete here

      // })
      // .catch(e => alert(e));
      var result = {};
      var score = [this.tot_user_score];
      db.collection("tests" + this.page_id)
        .doc(this.test_id)
        .collection("Stats")
        .doc("Score")
        .get()
        .then(doc => {
          if (doc.exists) {
            result = doc.data();
            score = result.score;
            score.push(this.tot_user_score);
            var totalScore = 0;
            for (var i = 0; i < score.length; i++) {
              totalScore = totalScore + score[i];
            }
            var average = totalScore / score.length;
            var totalMarks = this.test_total;
            db.collection("tests" + this.page_id)
              .doc(this.test_id)
              .collection("Stats")
              .doc("Score")
              .update({
                score: score,
                average: average,
                totalMarks: totalMarks
              });
          } else {
            let average = score / score.length;
            let totalMarks = this.test_total;
            db.collection("tests" + this.page_id)
              .doc(this.test_id)
              .collection("Stats")
              .doc("Score")
              .set({
                score: score,
                average: average,
                totalMarks: totalMarks
              });
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });

      // this.saveTestData();
    },
    async saveTestData() {
      await db
        .collection("tests" + this.page_id)
        .doc(this.test_id)
        .collection("test_takers")
        .doc(this.user_test_id)
        .update({ reviewed: true, score: this.tot_user_score });

      let userId = this.user_test_id;
      console.log(userId);
      console.log(this.page_id);
      await db
        .collection("tests" + this.page_id)
        .doc(this.test_id)
        .get()
        .then(async test => {
          console.log(test.data());
          this.total = test.data().total;
          this.title = test.data().title;
          this.startTime = test.data().start.seconds;
          let less = 0;

          var li = await db
            .collection("tests" + this.page_id)
            .doc(this.test_id)
            .collection("test_takers")
            .where("reviewed", "==", true)
            .get();
          this.num = li.size;
          this.rank = this.num;

          var tempCollection = [];
          li.forEach(collection => {
            if (collection.id == this.user_test_id)
              this.score = collection.data().score;
            tempCollection.push(collection.data());
          });
          for (let dat of tempCollection) {
            if (this.score != null && dat.score <= this.score) less += 1;
          }
          this.rank -= less;
          this.rank += 1;
          this.percentile = (less * 100.0) / this.num;

          console.log(`rank ${this.rank}   /  percentile ${this.percentile}`);

          db.collection("past_performance")
            .doc(userId)
            .get()
            .then(data => {
              let pastTestData = data.data() || {};
              console.log(pastTestData);
              if ("name" in pastTestData) {
                pastTestData.number_of_test_given += 1;
                pastTestData.best_rank.push(this.rank);
                pastTestData.best_percentile.push(this.percentile);
                pastTestData.name = this.user_det.name;
              } else {
                pastTestData["number_of_test_given"] += 1;
                pastTestData["best_rank"] = [this.rank];
                pastTestData["number_of_test_given"] = 1;
                pastTestData["best_percentile"] = [this.percentile];

                pastTestData["name"] = this.user_det.name;
              }

              db.collection("past_performance")
                .doc(userId)
                .set(pastTestData);
            });
        });
      await this.$router.push({
        name: "TestNotifications",
        params: { page_id: this.page_id }
      });
    },
    redirect() {
      this.$router.push({
        name: "TestNotifications",
        params: { page_id: this.page_id }
      });
    },
    viewFullResponse() {
      console.log(this.user_test_id);
      this.$router.push({
        name: "MyResponses",

        query: {
          page_id: this.page_id,
          test_id: this.test_id,
          user_test_id: this.user_test_id
        }
      });
    }
  }
};
</script>
<style scoped>
.mybtn {
  margin-top: 30px;
}
</style>
